import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { IconActionCheck, Space, Tag } from '@hse-design/react';
import { spacingSmall, colorBrand } from '@hse-design/tokens';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tag"
    }}>{`Tag`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Tag } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=289%3A0'} storybook={'/?path=/story/tag-tag--playground'} vueStorybook={'/?path=/story/tag-tag--playground'} name={'Tag'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Tag — это компонент для отображения ключевых слов, категорий контента. Можно использовать его в описании материалов.`}</p>
    <p>{`У компонента два размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{` и `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, по умолчанию используется `}<inlineCode parentName="p">{`small`}</inlineCode>{`. Передайте в проп `}<inlineCode parentName="p">{`size`}</inlineCode>{` нужный.`}</p>
    <p>{`Тегу можно добавить иконку справа и слева. Для этого передайте в проп `}<inlineCode parentName="p">{`leftIcon`}</inlineCode>{` или `}<inlineCode parentName="p">{`rightIcon`}</inlineCode>{` компонент иконки.`}</p>
    <p>{`Компонент имеет кликабельный вариант. Для включения передайте проп `}<inlineCode parentName="p">{`clickable`}</inlineCode>{` и добавьте обработчик `}<inlineCode parentName="p">{`onClick`}</inlineCode>{`.`}</p>
    <p>{`У `}<inlineCode parentName="p">{`Tag`}</inlineCode>{` есть 4 состояния: `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, `}<inlineCode parentName="p">{`focused`}</inlineCode>{`, `}<inlineCode parentName="p">{`hovered`}</inlineCode>{`, `}<inlineCode parentName="p">{`selected`}</inlineCode>{`. Для включения состояния передайте одноименный проп`}</p>
    <Playground __position={1} __code={'<Tag size={Tag.Size.medium}>Tag</Tag>'} __scope={{
      props,
      DefaultLayout,
      IconActionCheck,
      Space,
      Tag,
      spacingSmall,
      colorBrand,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Tag size={Tag.Size.medium} mdxType="Tag">Tag</Tag>
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node={'?node-id=9791:479'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node={'?node-id=9791:557'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "вариант-отображения"
    }}>{`Вариант отображения`}</h3>
    <p>{`У компонента четыре варианта отображения: `}<inlineCode parentName="p">{`primary`}</inlineCode>{`, `}<inlineCode parentName="p">{`secondary`}</inlineCode>{`, `}<inlineCode parentName="p">{`primaryInvert`}</inlineCode>{` и `}<inlineCode parentName="p">{`secondaryInvert`}</inlineCode>{`, по умолчанию используется `}<inlineCode parentName="p">{`primary`}</inlineCode>{`. Передайте в проп `}<inlineCode parentName="p">{`variant`}</inlineCode>{` нужный`}</p>
    <Playground __position={4} __code={'<div style={{ display: \'flex\' }}>\n  <Tag variant=\"primary\">Primary Tag</Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"primary\" disabled={true}>\n    Primary Disabled Tag\n  </Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"primary\" hovered={true}>\n    Primary Hovered Tag\n  </Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"primary\" selected={true}>\n    Primary Selected Tag\n  </Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"primary\" focused={true}>\n    Primary Focused Tag\n  </Tag>\n</div>'} __scope={{
      props,
      DefaultLayout,
      IconActionCheck,
      Space,
      Tag,
      spacingSmall,
      colorBrand,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: "flex"
      }}>
    <Tag variant="primary" mdxType="Tag">
      Primary Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="primary" disabled={true} mdxType="Tag">
      Primary Disabled Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="primary" hovered={true} mdxType="Tag">
      Primary Hovered Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="primary" selected={true} mdxType="Tag">
      Primary Selected Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="primary" focused={true} mdxType="Tag">
      Primary Focused Tag
    </Tag>
  </div>
    </Playground>
    <Playground __position={5} __code={'<div\n  style={{\n    display: \'flex\',\n    backgroundColor: colorBrand,\n    padding: spacingSmall,\n  }}\n>\n  <Tag variant=\"primaryInvert\">PrimaryInvert Tag</Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"primaryInvert\" disabled={true}>\n    PrimaryInvert Disabled Tag\n  </Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"primaryInvert\" hovered={true}>\n    PrimaryInvert Hovered Tag\n  </Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"primaryInvert\" selected={true}>\n    PrimaryInvert Selected Tag\n  </Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"primaryInvert\" focused={true}>\n    PrimaryInvert Focused Tag\n  </Tag>\n</div>'} __scope={{
      props,
      DefaultLayout,
      IconActionCheck,
      Space,
      Tag,
      spacingSmall,
      colorBrand,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        backgroundColor: colorBrand,
        padding: spacingSmall
      }}>
    <Tag variant="primaryInvert" mdxType="Tag">
      PrimaryInvert Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="primaryInvert" disabled={true} mdxType="Tag">
      PrimaryInvert Disabled Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="primaryInvert" hovered={true} mdxType="Tag">
      PrimaryInvert Hovered Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="primaryInvert" selected={true} mdxType="Tag">
      PrimaryInvert Selected Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="primaryInvert" focused={true} mdxType="Tag">
      PrimaryInvert Focused Tag
    </Tag>
  </div>
    </Playground>
    <Playground __position={6} __code={'<div style={{ display: \'flex\' }}>\n  <Tag variant=\"secondary\">Secondary Tag</Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"secondary\" disabled={true}>\n    Secondary Disabled Tag\n  </Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"secondary\" hovered={true}>\n    Secondary Hovered Tag\n  </Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"secondary\" selected={true}>\n    Secondary Selected Tag\n  </Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"secondary\" focused={true}>\n    Secondary Focused Tag\n  </Tag>\n</div>'} __scope={{
      props,
      DefaultLayout,
      IconActionCheck,
      Space,
      Tag,
      spacingSmall,
      colorBrand,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: "flex"
      }}>
    <Tag variant="secondary" mdxType="Tag">
      Secondary Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="secondary" disabled={true} mdxType="Tag">
      Secondary Disabled Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="secondary" hovered={true} mdxType="Tag">
      Secondary Hovered Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="secondary" selected={true} mdxType="Tag">
      Secondary Selected Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="secondary" focused={true} mdxType="Tag">
      Secondary Focused Tag
    </Tag>
  </div>
    </Playground>
    <Playground __position={7} __code={'<div\n  style={{\n    display: \'flex\',\n    backgroundColor: colorBrand,\n    padding: spacingSmall,\n  }}\n>\n  <Tag variant=\"secondaryInvert\">SecondaryInvert Tag</Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"secondaryInvert\" disabled={true}>\n    SecondaryInvert Disabled Tag\n  </Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"secondaryInvert\" hovered={true}>\n    SecondaryInvert Hovered Tag\n  </Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"secondaryInvert\" selected={true}>\n    SecondaryInvert Selected Tag\n  </Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag variant=\"secondaryInvert\" focused={true}>\n    SecondaryInvert Focused Tag\n  </Tag>\n</div>'} __scope={{
      props,
      DefaultLayout,
      IconActionCheck,
      Space,
      Tag,
      spacingSmall,
      colorBrand,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        backgroundColor: colorBrand,
        padding: spacingSmall
      }}>
    <Tag variant="secondaryInvert" mdxType="Tag">
      SecondaryInvert Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="secondaryInvert" disabled={true} mdxType="Tag">
      SecondaryInvert Disabled Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="secondaryInvert" hovered={true} mdxType="Tag">
      SecondaryInvert Hovered Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="secondaryInvert" selected={true} mdxType="Tag">
      SecondaryInvert Selected Tag
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag variant="secondaryInvert" focused={true} mdxType="Tag">
      SecondaryInvert Focused Tag
    </Tag>
  </div>
    </Playground>
    <h3 {...{
      "id": "размер"
    }}>{`Размер`}</h3>
    <p>{`У компонента три размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{` и `}<inlineCode parentName="p">{`large`}</inlineCode>{`, по умолчанию используется `}<inlineCode parentName="p">{`small`}</inlineCode>{`. Передайте в проп `}<inlineCode parentName="p">{`size`}</inlineCode>{` нужный`}</p>
    <Playground __position={8} __code={'<Tag>Small</Tag>\n<Space size={Space.Size.small_3x} vertical />\n<Tag size={Tag.Size.medium}>Medium</Tag>\n<Space size={Space.Size.small_3x} vertical />\n<Tag size={Tag.Size.large}>Large</Tag>'} __scope={{
      props,
      DefaultLayout,
      IconActionCheck,
      Space,
      Tag,
      spacingSmall,
      colorBrand,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Tag mdxType="Tag">Small</Tag>
  <Space size={Space.Size.small_3x} vertical mdxType="Space" />
  <Tag size={Tag.Size.medium} mdxType="Tag">
    Medium
  </Tag>
  <Space size={Space.Size.small_3x} vertical mdxType="Space" />
  <Tag size={Tag.Size.large} mdxType="Tag">
    Large
  </Tag>
    </Playground>
    <h3 {...{
      "id": "иконка"
    }}>{`Иконка`}</h3>
    <p>{`Тегу можно добавить иконку справа и слева. Для этого передайте в проп `}<inlineCode parentName="p">{`leftIcon`}</inlineCode>{` или `}<inlineCode parentName="p">{`rightIcon`}</inlineCode>{` компонент иконки`}</p>
    <Playground __position={9} __code={'<div style={{ display: \'flex\' }}>\n  <Tag size={Tag.Size.medium} leftIcon={IconActionCheck}>\n    Иконка слева\n  </Tag>\n  <Space size={Space.Size.small_3x} vertical horizontal />\n  <Tag size={Tag.Size.medium} rightIcon={IconActionCheck}>\n    Иконка справа\n  </Tag>\n</div>'} __scope={{
      props,
      DefaultLayout,
      IconActionCheck,
      Space,
      Tag,
      spacingSmall,
      colorBrand,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: "flex"
      }}>
    <Tag size={Tag.Size.medium} leftIcon={IconActionCheck} mdxType="Tag">
      Иконка слева
    </Tag>
    <Space size={Space.Size.small_3x} vertical horizontal mdxType="Space" />
    <Tag size={Tag.Size.medium} rightIcon={IconActionCheck} mdxType="Tag">
      Иконка справа
    </Tag>
  </div>
    </Playground>
    <h3 {...{
      "id": "кликабельный-вариант"
    }}>{`Кликабельный вариант`}</h3>
    <p>{`Компонент имеет кликабельный вариант. Для включения передайте проп `}<inlineCode parentName="p">{`clickable`}</inlineCode>{` и добавьте обработчик `}<inlineCode parentName="p">{`onClick`}</inlineCode>{`.`}</p>
    <Playground __position={10} __code={'<Tag size={Tag.Size.medium} clickable component={\'button\'}>\n  Clickable Tag\n</Tag>'} __scope={{
      props,
      DefaultLayout,
      IconActionCheck,
      Space,
      Tag,
      spacingSmall,
      colorBrand,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Tag size={Tag.Size.medium} clickable component={'button'} mdxType="Tag">
    Clickable Tag
  </Tag>
    </Playground>
    <h3 {...{
      "id": "состояние"
    }}>{`Состояние`}</h3>
    <p>{`У `}<inlineCode parentName="p">{`Tag`}</inlineCode>{` есть 4 состояния: `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, `}<inlineCode parentName="p">{`focused`}</inlineCode>{`, `}<inlineCode parentName="p">{`hovered`}</inlineCode>{`, `}<inlineCode parentName="p">{`selected`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`disabled`}</inlineCode>{` отображает отключенное состояние`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`focused`}</inlineCode>{` отображает сфокусированное состояние`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`focused`}</inlineCode>{` отображает состояние, включающееся при наведении на компонент`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`selected`}</inlineCode>{` отображает выбранное состояние`}</p>
      </li>
    </ul>
    <p>{`Для включения состояния передайте одноименный проп`}</p>
    <Playground __position={11} __code={'<div>\n  <Tag size=\"medium\" disabled>\n    Disabled\n  </Tag>\n  <Space size=\"small_3x\" vertical horizontal />\n  <Tag size=\"medium\" focused>\n    Focused\n  </Tag>\n  <Space size=\"small_3x\" vertical horizontal />\n  <Tag size=\"medium\" hovered>\n    Hovered\n  </Tag>\n  <Space size=\"small_3x\" vertical horizontal />\n  <Tag size=\"medium\" selected>\n    Selected\n  </Tag>\n</div>'} __scope={{
      props,
      DefaultLayout,
      IconActionCheck,
      Space,
      Tag,
      spacingSmall,
      colorBrand,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <Tag size="medium" disabled mdxType="Tag">
      Disabled
    </Tag>
    <Space size="small_3x" vertical horizontal mdxType="Space" />
    <Tag size="medium" focused mdxType="Tag">
      Focused
    </Tag>
    <Space size="small_3x" vertical horizontal mdxType="Space" />
    <Tag size="medium" hovered mdxType="Tag">
      Hovered
    </Tag>
    <Space size="small_3x" vertical horizontal mdxType="Space" />
    <Tag size="medium" selected mdxType="Tag">
      Selected
    </Tag>
  </div>
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Tag} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на корневой элемент в соответствии с пропом `}<inlineCode parentName="p">{`component`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      